$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 130; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-nav-button").click(function(){
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");
        $(".js-header").toggleClass("navActive");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * marquee
    // *
    var $marqueeDisabled = $(".js-marquee-disabled");

    if ($marqueeDisabled.length) {
        $marqueeDisabled.each(function(){
            $(this).slick({
                fade: false,
                dots: false,
                arrows: false,
                autoplay: false,
                waitForAnimate: false,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: false,
                rows: 0,
                infinite: true,
                speed: 8000,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * marquee
    // *
    var $marquee = $(".js-marquee");

    if ($marquee.length) {
        $marquee.each(function(){
            $(this).slick({
                fade: false,
                dots: false,
                arrows: false,
                autoplay: true,
                waitForAnimate: false,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: false,
                rows: 0,
                infinite: true,
                speed: 8000,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * team
    // *
    var $team = $(".js-team");

    if ($team.length) {
        $team.each(function(){
            $(this).slick({
                fade: false,
                dots: false,
                arrows: false,
                autoplay: true,
                waitForAnimate: false,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: true,
                rows: 0,
                infinite: true,
                speed: 9500,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * news
    // *
    var $news = $(".js-news");

    if ($news.length) {
        $news.each(function(){
            var $newsWrapper = $(this).parents(".js-news-wrapper");

            $(this).slick({
                fade: false,
                dots: false,
                arrows: true,
                autoplay: true,
                waitForAnimate: true,
                slidesToScroll: 1,
                variableWidth: false,
                draggable: true,
                rows: 0,
                infinite: true,
                useTransform: true,
                speed: 2000,
                autoplaySpeed: 4000,
                prevArrow: $newsWrapper.find(".js-news-nav-prev"),
                nextArrow: $newsWrapper.find(".js-news-nav-next"),
                responsive: [
                    {
                      breakpoint: 750,
                      settings: {
                        variableWidth: true,
                      }
                    }
                  ]
            });

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $newsWrapper.find(".js-slider-total").html(total);
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * footer content
    // *
    // *
    $(".js-footer-button").click(function() {
        $(this).toggleClass("active");
        $(".js-footer-content").slideToggle();
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * stores
    // *
    // *
    if ($(".js-stores-wrapper").length) {

        // toggle image
        $(".js-stores-item").mouseenter(function() {
            var index = $(this).index();
            $(this).parents(".js-stores-wrapper").find(".js-stores-figure").eq(index).addClass("active").siblings().removeClass("active");
        })

        // detect touch screen
        if(('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {

            $(".js-stores-item").click(function() {

                // first click only toggles image
                if (!$(this).hasClass("open")) {
                    event.preventDefault();
                    var index = $(this).index();
                    $(this).parents(".js-stores-wrapper").find(".js-stores-figure").eq(index).addClass("active").siblings().removeClass("active");
                }

                $(this).addClass("open").siblings().removeClass("open");
            })
        };

        $(".js-stores-figure").eq(0).addClass("active");
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * $events
    // *
    var $events = $(".js-events");

    if ($events.length) {
        $events.each(function(){
            var $eventsWrapper = $(this).parents(".js-events-wrapper");

            $(this).slick({
                fade: false,
                dots: false,
                arrows: true,
                autoplay: true,
                waitForAnimate: true,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: true,
                rows: 0,
                infinite: true,
                useTransform: true,
                speed: 2000,
                autoplaySpeed: 4000,
                prevArrow: $eventsWrapper.find(".js-events-nav-prev"),
                nextArrow: $eventsWrapper.find(".js-events-nav-next"),
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * events overlay
    // *
    // *
    var $eventOverlay = $(".js-event-overlay");

    if ($eventOverlay.length) {
        $(".js-event-overlay-button").click(function(){
            $eventOverlay.toggleClass("active");
            $("html, body").toggleClass("freeze");
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * calendar
    // *
    var $calendar = $(".js-calendar-slider");

    if ($calendar.length) {
        // var $newsWrapper = $(this).parents(".js-news-wrapper");

        $calendar.slick({
            fade: false,
            dots: false,
            arrows: true,
            autoplay: false,
            waitForAnimate: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            variableWidth: false,
            draggable: true,
            infinite: false,
            speed: 1000,
            initialSlide: 12,
            prevArrow: $(".js-calendar-nav-prev"),
            nextArrow: $(".js-calendar-nav-next"),
            responsive: [
                {
                  breakpoint: 900,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                  }
                }
              ]
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * calendar city filter
    // *
    $(".js-event-calendar-filter").click(function(){
        var city = $(this).data("event-calendar-filter-city");

        $(this).addClass("active").siblings().removeClass("active");

        if (city > 0){
            $(".js-calendar-event").removeClass("active");
            $('[data-city="'+ city +'"]').addClass("active");
        }else {
            $(".js-calendar-event").addClass("active");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * calendar event preview positioning
    // *
    $(".js-calendar-month-grid").mouseover(function() {
        $(".js-event-preview").each(function(){
            var parentsWidth = $(this).parents(".js-calendar-month-grid").width(),
                posLeft =  $(this).parents(".js-calendar-day").position().left,
                posRight =  parentsWidth - (posLeft + $(this).parents(".js-calendar-day").width());

            if (posLeft <= 80) $(this).addClass("left");
            if (posRight <= 80) $(this).addClass("right");
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popUp
    // *
    // *
    $("[data-popup]").click(function(event){
        event.preventDefault();
        var id = $(this).data("popup");
        window.location.hash = id;
        openPopUp($("#" + id));
    });

    // open popup if exists
    if(window.location.hash) {
        var hash = window.location.hash;
        if (hash && hash != 'a' && $(hash).length && $(".js-popup").length) {
            openPopUp($(hash));
        }
    }

    // open popUp
    function openPopUp($object) {
        $object.addClass("active");
        $("html, body, .js-event-overlay").addClass("freeze");
    }

    // close popUp
    $(".js-popup-close").click(function(event){
        event.preventDefault();
        window.location.hash = "a";
        $(".js-popup").removeClass("active");
        $(".js-event-overlay").removeClass("freeze");
        if (!$(".js-event-overlay").hasClass("active")){
           $("html, body").removeClass("freeze");
        }
    });


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 30) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
});
